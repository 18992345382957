<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_USERS_EDIT)"
        class-name="admin-user-edit-page"
        :loading="loading"
        :title="['Admin', 'Users', 'Edit']"
        header="Edit user"
        :skewed-footer-foreground="(!fetchedData && loading ? '#fff' : null)"
        pre-max-width="800px"
        stretch-first-container
        :actions="actions"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/users">Users</f-breadcrumb>
                <f-breadcrumb
                    :link="`/admin/users/${$route.params.id}`"
                    :loading="!dataLoaded"
                >
                    {{ fields.first_name + ' ' + fields.last_name }}
                </f-breadcrumb>
                <f-breadcrumb>Edit</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-form
            enctype="multipart/form-data"
            @submitValid="(dataLoaded ? submit() : null)"
            validate
        >
            <f-container max-width="800px">
                <div v-if="dataLoaded">
                    <f-form-row label="Image">
                        <f-image-input
                            ref="profileImageUploadField"
                            name="image"
                            :src="fetchedData.data.profile_image"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="First name">
                        <f-text-input
                            name="first name"
                            v-model="fields.first_name"
                            max-width="300px"
                            readonly
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row label="Last name">
                        <f-text-input
                            name="last name"
                            readonly
                            v-model="fields.last_name"
                            max-width="300px"
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row label="Type">
                        <f-dropdown
                            name="type"
                            v-model="fields.type"
                            max-width="160px"
                            readonly
                            :options="[
                                {key: 'employee', value: 'employee'},
                                {key: 'freelancer', value: 'freelancer'},
                            ]"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Gender">
                        <f-dropdown
                            name="gender"
                            v-model="fields.gender"
                            max-width="160px"
                            :options="genderOptions"
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row label="Pronouns">
                        <f-text-input
                            v-model="fields.pronouns"
                            readonly
                            max-width="260px"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row>
                        <template #label>
                            Discipline <sup>DEPRECATED</sup>
                        </template>

                        <f-discipline-dropdown
                            name="discipline"
                            v-model="fields.discipline_id"
                        />
                    </f-form-row>

                    <f-form-row label="Job title">
                        <f-text-input
                            name="job title"
                            v-model="fields.title"
                            max-width="300px"
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Office">
                        <f-dropdown
                            v-model="fields.office"
                            :options="officeOptions"
                            :validation="['required']"
                            popup-width="120px"
                            name="office"
                            width="250px"
                            readonly
                            pre-icon="apartment"
                        />
                    </f-form-row>

                    <f-form-row label="Email">
                        <f-text-input
                            v-model="fields.email"
                            name="email"
                            :validation="['required', 'email']"
                            max-width="340px"
                            readonly
                            pre-icon="envelope"
                        />
                    </f-form-row>

                    <f-form-row
                        label="Lead"
                        help="Employee's lead will be cc'ed when requesting leave"
                    >
                        <f-user-dropdown
                            v-model="fields.lead_user_id"
                            max-width="260px"
                            pre-icon="user"
                            resetable
                        />
                    </f-form-row>
                    <f-form-row
                        label="Org. node parent"
                        help="Employee's parent node in the organizational chart"
                    >
                        <f-flex gap="6px" main-axis-align="flex-start">
                            <f-dropdown
                                v-model="fields.org_parent_type"
                                :options="[
                                    { key: 'employee', value: 'employee' },
                                    { key: 'top', value: 'top' },
                                ]"
                                :validation="['required']"
                                popup-width="120px"
                                width="140px"
                            />
                            <f-user-dropdown
                                v-if="fields.org_parent_type === 'employee'"
                                v-model="fields.org_parent_id"
                                max-width="260px"
                                pre-icon="user"
                                resetable
                            />
                        </f-flex>
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row
                        label="Started at"
                        help="Started working on"
                    >
                        <f-date-picker
                            v-model="fields.started_at"
                            max-width="200px"
                            pre-icon="calendar-user"
                            class-name="--align-left"
                            :show-week-numbers="false"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row
                        label="Phone number"
                        help="Will be automatically parsed to a human readable format"
                    >
                        <f-text-input
                            v-model="fields.phone_number"
                            max-width="250px"
                            pre-icon="telephone2"
                        />
                    </f-form-row>

                    <f-form-row label="City">
                        <f-text-input
                            v-model="fields.city"
                            max-width="250px"
                            pre-icon="home"
                        />
                    </f-form-row>

                    <f-form-row label="Date of birth">
                        <f-date-picker
                            v-model="fields.date_of_birth"
                            max-width="200px"
                            pre-icon="gift"
                            class-name="--align-left"
                            :show-week-numbers="false"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row
                        label="Hide from list"
                        help="If activated and hidden from the list, this user will not be shown in on the people page, but will have a functional Riviera account"
                    >
                        <f-switch v-model="fields.hide_from_list"/>
                    </f-form-row>

                    <!--                    <f-form-row-->
                    <!--                        label="Sync signature"-->
                    <!--                        help="Automatically update signature when changes occur"-->
                    <!--                    >-->
                    <!--                        <f-switch v-model="fields.sync_signature"/>-->
                    <!--                    </f-form-row>-->

                    <f-form-row-spacer/>

                    <f-form-row
                        label="Description"
                        help="Visible on the people page, describes the roles & responsibilities of this employee"
                    >
                        <f-editor
                            :toolbar="getEditorToolbar()"
                            v-model="fields.description"
                        />
                    </f-form-row>
                </div>

                <f-form-placeholder v-else-if="!dataLoaded && loading"/>
            </f-container>

            <skewed-section
                v-if="dataLoaded"
                foreground-color="#333"
                after-background="#222"
                padding="100px 0 150px"
                margin="100px 0 -50px"
            >
                <f-theme-provider variant="dark">
                    <f-container max-width="800px">
                        <bullet-point>Riviera permissions</bullet-point>
                        <permissions-checkbox-list v-model="fields.permissions"/>
                    </f-container>
                </f-theme-provider>
            </skewed-section>

            <entity-action-button
                v-if="dataLoaded"
                icon="floppy-disk"
                tooltip="Update"
                type="submit"
            />
        </f-form>
    </page>
</template>

<script>
import APIDataStoreResources from '../../../../config/APIDataStoreResources';
import APIEndpoints from '../../../../config/APIEndpoints';
import {EditorToolbar} from "../../../../f-components/form-controls/editor/config";
import APIDataStore from '../../../../services/APIDataStore';
import OfficeOptionsMixin from '../../../../mixins/OfficeOptionsMixin';
import FForm from '../../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../../f-components/form-controls/forms/FFormRow';
import FImageInput from '../../../../f-components/form-controls/images/FImageInput';
import FFormRowSpacer from '../../../../f-components/form-controls/forms/FFormRowSpacer';
import Page from '../../Page';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import FEditor from '../../../../f-components/form-controls/editor/FEditor';
import FDropdown from '../../../../f-components/form-controls/dropdowns/FDropdown';
import FDatePicker from '../../../../f-components/form-controls/dates/FDatePicker';
import FSwitch from '../../../../f-components/form-controls/switches/FSwitch';
import FUserDropdown from '../../../../f-components/form-controls/dropdowns/FUserDropdown';
import AdminUserMixin from './AdminUserMixin';
import FDisciplineDropdown from '../../../../f-components/form-controls/dropdowns/FDisciplineDropdown';
import EntityCreateEditMixin from "../../../../mixins/entities/EntityCreateEditMixin";
import PermissionsCheckboxList from "./components/PermissionsCheckboxList";
import FContainer from '../../../../f-components/layout/container/FContainer';
import FFormPlaceholder from '../../../../f-components/loaders/placeholder/FFormPlaceholder';
import SkewedSection from '../../../misc/skewed-section/SkewedSection';
import FThemeProvider from '../../../../f-components/layout/theme/FThemeProvider';
import BulletPoint from '../../../misc/BulletPoint';
import {Permissions} from '../../../../config/Permissions';
import {Icons} from '../../../../f-components/layout/icons/config';
import EntityActionButton from '../../../misc/entities/EntityActionButton';
import {Genders} from '../../../../config/Genders';
import FFlex from '../../../../f-components/layout/flex/FFlex';

export default {
    name: 'admin-user-edit-page',

    components: {
        FFlex,
        EntityActionButton,
        BulletPoint,
        FThemeProvider,
        SkewedSection,
        FFormPlaceholder,
        FContainer,
        PermissionsCheckboxList,
        FDisciplineDropdown,
        FUserDropdown,
        FSwitch,
        FDatePicker,
        FDropdown,
        FEditor,
        FTextInput,
        FBreadcrumbs,
        FBreadcrumb,
        Page,
        FFormRowSpacer,
        FImageInput,
        FFormRow,
        FForm,
    },

    mixins: [
        OfficeOptionsMixin,
        AdminUserMixin,
        EntityCreateEditMixin,
    ],

    data() {
        return {
            entityName: 'user',
            fields: {
                first_name: null,
                last_name: null,
                type: null,
                title: null,
                discipline_id: null,
                email: null,
                gender: null,
                pronouns: null,
                city: null,
                office: null,
                date_of_birth: null,
                hide_from_list: false,
                lead_user_id: null,
                org_parent_type: null,
                org_parent_id: null,
                permissions: [],
                phone_number: null,
                started_at: null,
                description: null,
                sync_signature: true,
            },
            genderOptions: Object.entries(Genders).map(([key, value]) => ({key, value})),
        }
    },

    computed: {
        actions() {
            if (!this.fetchedData || !this.hasPermission(Permissions.ADMIN_USERS_EDIT)) {
                return [];
            }

            const actions = [];

            if (!this.fetchedData.data.activated_at && !this.fetchedData.data.deleted_at) {
                actions.push({
                    value: 'Activate user',
                    icon: Icons.PULSE,
                    onClick: () => {
                        this.activateUser(this.$route.params.id, () => this.refresh());
                    },
                });
            }

            if (this.fetchedData.data.activated_at && !this.fetchedData.data.deleted_at) {
                actions.push({
                    value: 'Deactivate user',
                    icon: Icons.SKULL,
                    onClick: () => {
                        this.deactivateUser(this.$route.params.id, () => this.refresh());
                    },
                });
            }

            if (!this.fetchedData.data.deleted_at) {
                actions.push({
                    value: 'Delete user',
                    icon: Icons.TRASH2,
                    onClick: () => {
                        this.deleteUser(this.$route.params.id, () => this.refresh());
                    },
                });
            }

            return actions;
        },
    },

    created() {
        APIDataStore.check(APIDataStoreResources.DEPARTMENTS_AND_DISCIPLINES_COLLECTION);
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.ADMIN_USERS;
        },

        getRedirectPath() {
            return '/admin/users';
        },

        getEditorToolbar() {
            return [
                EditorToolbar.BOLD,
                EditorToolbar.ITALIC,
                EditorToolbar.HARD_BREAK,
                EditorToolbar.LINK,
                EditorToolbar.STRIKE,
                EditorToolbar.UNDERLINE,
                EditorToolbar.BULLET_LIST,
                EditorToolbar.HISTORY,
            ];
        },

        configureFormData(formData) {
            const file = this.$refs.profileImageUploadField.getFile();
            if (file) {
                formData.set('profile_image', file);
            }
            return formData;
        },
    },
}
</script>

<style lang="scss">
.admin-user-edit-page {
    .bullet-point {
        margin-bottom: 50px;
    }
}
</style>
