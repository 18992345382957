<template>
    <aside :class="['main-menu', mobileMenuActive ? '--open' : null]">
        <div class="main-menu__content">
            <router-link to="/" class="main-menu__content__logo">
                <logo/>
            </router-link>

            <f-close @click="EventBus.$emit('mobile.menu.close')"/>

            <ul
                :class="[
                    'main-menu__content__list',
                    '--no-select',
                    '--scrollable-dark',
                ]"
                @scroll="EventBus.$emit(GlobalEvents.OVERFLOW_SCROLL)"
            >
                <main-menu-item
                    id="peopleMenu"
                    link="/people"
                    icon="users2"
                    title="People"
                    subtitle="The people of code d'azur"
                />
                <main-menu-item
                    v-if="['amsterdam'].includes(userOffice)"
                    id="meetingRoomsMenu"
                    :link="`/meeting-rooms/${userOffice}`"
                    icon="calendar-full"
                    title="Meeting <br>Rooms"
                    subtitle="Meeting rooms calendar"
                />
                <main-menu-item
                    id="faqMenu"
                    link="/faq"
                    icon="book"
                    title="FAQs &amp; <br>Guides"
                    subtitle="Company information & FAQ"
                />
                <main-menu-item
                    id="hrMenu"
                    link="/hr"
                    icon="clipboard-user"
                    title="HR"
                    subtitle="People-first at code d’azur HR"
                />
                <!--                <main-menu-item-->
                <!--                    v-if="hasPermission(Permissions.PROJECTS_VIEW)"-->
                <!--                    style="opacity: .1"-->
                <!--                    id="projectsMenu"-->
                <!--                    link="/projects"-->
                <!--                    icon="clipboard-text"-->
                <!--                    title="Projects <sup>DEPRECATED</sup>"-->
                <!--                    subtitle="List of all projects and their accountability"-->
                <!--                />-->
                <!--                <main-menu-item-->
                <!--                    v-if="hasPermission(Permissions.LEAVE_REQUESTS_VIEW)"-->
                <!--                    style="opacity: .1"-->
                <!--                    icon="clock3"-->
                <!--                    title="Leave Requests <sup>DEPRECATED</sup>"-->
                <!--                    subtitle="View and approve leave requests"-->
                <!--                    link="/leave-requests"-->
                <!--                    :badge="adminLeaveRequestsCount"-->
                <!--                />-->
                <main-menu-item
                    v-if="hasPermission(Permissions.FREELANCERS_VIEW)"
                    link="/freelancers"
                    icon="contacts"
                    title="Freelancer <br>Database"
                    subtitle="Manage the freelancer jungle"
                />
                <main-menu-item
                    link="/office-map/amsterdam"
                    icon="map"
                    title="Amsterdam<br> Office Map"
                    subtitle="Interactive 3D office map"
                />
                <li class="main-menu-item__grow"/>
                <main-menu-item
                    id="logoutMenu"
                    icon="power-switch"
                    title="Logout"
                    subtitle="Logout of Riviera"
                    @click="EventBus.$emit(GlobalEvents.LOGOUT)"
                />
            </ul>
        </div>
    </aside>
</template>

<script>
import MainMenuItem from "./MainMenuItem";
import Logo from "../misc/Logo";
import FClose from "../../f-components/navigation/close/FClose";
import EventBus from "../../services/EventBus";
import {GlobalEvents} from "../../config/Events";
import UserOfficeMixin from "../../mixins/UserOfficeMixin";

export default {
    name: "main-menu",

    mixins: [UserOfficeMixin],

    components: {
        FClose,
        Logo,
        MainMenuItem,
    },

    computed: {
        // adminLeaveRequestsCount() {
        //     if (this.$store.state && this.$store.state.$adminLeaveRequests && this.$store.state.$adminLeaveRequests.unread > 0) {
        //         return (this.$store.state.$adminLeaveRequests.unread > 99 ? '99+' : this.$store.state.$adminLeaveRequests.unread);
        //     }
        //     return false;
        // },
    },

    data() {
        return {
            mobileMenuActive: false,
        };
    },

    created() {
        EventBus.$on("mobile.menu.open", () => (this.mobileMenuActive = true));
        EventBus.$on(
            "mobile.menu.close",
            () => (this.mobileMenuActive = false)
        );
        EventBus.$on(
            GlobalEvents.PAGE_CHANGE,
            () => (this.mobileMenuActive = false)
        );
    },
};
</script>

<style lang="scss">
.main-menu {
    flex: 0 0 $menuWidth;
    color: $darkFront;
    background: $dark;
    width: $menuWidth;
    z-index: $mainMenuOrder;

    @include media(mobile-down) {
        position: fixed;
        width: 100%;
        height: 100vh;
        left: -100%;
        transition: left 0.2s;
    }

    &.--open {
        @include media(mobile-down) {
            left: 0;
        }
    }

    &__content {
        background: $dark;
        width: $menuWidth;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media(mobile-down) {
            position: relative;
            left: inherit;
            right: inherit;
            width: 100%;
        }

        &__logo {
            padding: 40px 0;

            .logo {
                width: 30px;
                height: 30px;

                @include media(mobile-down) {
                    width: 50px;
                    height: 50px;
                }

                * {
                    fill: $darkFront;
                }
            }

            &:hover {
                .logo * {
                    fill: $ctaOrange;
                }
            }

            .--christmas & {
                &::before {
                    content: "";
                    position: absolute;
                    background: url("../../../public/img/fun/christmas-hat.png");
                    background-size: contain;
                    width: calc(158px / 8);
                    height: calc(162px / 8);
                    z-index: 3;
                    top: 35px;
                    right: 17px;

                    @include media(mobile-down) {
                        display: none;
                    }
                }
            }
        }

        .f-close {
            display: none;
            position: absolute;
            top: 20px;
            right: 20px;

            @include media(mobile-down) {
                display: block;
            }
        }

        &__list {
            width: 100%;
            height: 100%;

            @include media(mobile-up) {
                display: flex;
                flex-direction: column;
            }
        }

        .main-menu-item__grow {
            flex-grow: 1;
        }
    }
}
</style>
