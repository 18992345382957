import APIEndpoints from "../../../config/APIEndpoints";
import ErrorHandler from "../../../services/ErrorHandler";
import EventBus from "../../../services/EventBus";
import axios from "axios";
import {GlobalEvents} from '../../../config/Events';
import LoadingMixin from '../../../mixins/LoadingMixin';

export default {
    mixins: [
        LoadingMixin,
    ],

    methods: {
        setArchived(id, archived) {
            this.startLoading();

            window.axios
                .post(`${APIEndpoints.FREELANCER_DATABASE}/${id}`, {
                    _method: 'PUT',
                    is_archived: archived,
                })
                .then(() => {
                    this.endLoading();
                    EventBus.$emit(GlobalEvents.PUSH_MESSAGE, (archived ? 'Successfully archived freelancer' : 'Removed freelancer from archive'));
                    this.$emit('refresh');
                })
                .catch(() => this.endLoading());
        },

        deleteFreelancer(id, name) {
            EventBus.$emit(GlobalEvents.ALERT_SHOW, {
                title: 'Delete freelancer',
                body: `Are you sure you want to delete ${name}?`,
                height: '200px',
                confirmButtonText: 'Delete',
                confirmButtonVariant: 'danger',
                cancelButton: true,
                onConfirm: () => {
                    this.startLoading();

                    axios['delete'](`${APIEndpoints.FREELANCER_DATABASE}/${id}`)
                        .then(() => {
                            this.endLoading();

                            // Add push message
                            EventBus.$emit(GlobalEvents.PUSH_MESSAGE, `Successfully deleted ${name}`);

                            // Redirect to index / refresh
                            if (this.$router.currentRoute.name !== 'freelancers') {
                                this.$router.push('/freelancers');
                            } else {
                                this.$emit('refresh');
                            }
                        })
                        .catch(error => {
                            this.endLoading();
                            ErrorHandler.onGeneralError(error);
                        });
                },
            });
        },
    },
};
