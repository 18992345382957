<template>
    <span
        class="icon-private icon f-icon f-icon-eye-crossed"
        v-tooltip="`This field is private and<br>not shown elsewhere in Riviera`"
    />
</template>

<script>
export default {
    name: 'icon-private',
}
</script>

<style lang="scss">
.icon-private {
    vertical-align: middle;
    margin-left: 3px;
}
</style>
