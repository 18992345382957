<template>
    <div
        class="working-periods-field"
        :style="`width: ${width}; max-width: ${maxWidth}`"
    >
        <working-periods-field-item
            v-for="(period, i) in dPeriods"
            :key="i"
            :id="period.id"
            :start-date="period.startDate"
            :end-date="period.endDate"
            :description="period.description"
            :readonly="readonly"
            @add="add"
            @remove="remove"
        />
    </div>
</template>

<script>
import WorkingPeriodsFieldItem from './WorkingPeriodsFieldItem';

export default {
    name: 'working-periods-field',

    components: {
        WorkingPeriodsFieldItem,
    },

    props: {
        readonly: Boolean,
        width: {
            type: String,
            default: '100%',
        },
        maxWidth: {
            type: String,
            default: 'none',
        },
        periods: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            dPeriods: [{}],
            count: 1,
        }
    },

    created() {
        if (this.periods.length > 0) {
            this.dPeriods = [];
            this.count = 0;
            this.periods.forEach((period) => {
                this.add(period.id, period.start_date, period.end_date, period.description);
            });
        }
    },

    methods: {
        removeFirst() {
            this.$children[0].remove();
        },

        add(id, startDate, endDate, description) {
            this.dPeriods.push({
                id: id,
                startDate: startDate,
                endDate: endDate,
                description: description,
            });

            this.count++;
        },

        remove() {
            this.count--;

            if (this.count === 0) this.add();
        },

        getData() {
            let data = [];

            for (let i in this.$children) {
                if (!this.$children[i].getData()) continue;
                data.push(this.$children[i].getData());
            }

            return data;
        },
    },
}
</script>
