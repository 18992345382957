<template>
    <page
        class-name="freelancer-database-edit-create-page"
        :loading="loading && !dataLoaded"
        :title="documentTitle"
        :header="mode.ucwords() + ' freelancer'"
        :skewed-footer-foreground="(!fetchedData && loading ? '#fff' : null)"
        pre-max-width="800px"
        stretch-first-container
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb link="/freelancers">
                    Freelancer Database
                </f-breadcrumb>
                <f-breadcrumb
                    v-if="mode === 'edit'"
                    :link="`/freelancers/${$route.params.id}`"
                    :loading="loading && !dataLoaded"
                >
                    {{ fields.first_name }} {{ fields.last_name }}
                </f-breadcrumb>
                <f-breadcrumb>{{ mode }}</f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-form
            enctype="multipart/form-data"
            @submitValid="(dataLoaded ? submit() : null)"
            validate
        >
            <f-container max-width="800px">
                <section v-if="dataLoaded">
                    <!--                    <f-inline-message-->
                    <!--                        variant="warning"-->
                    <!--                        id="freelancerWorkingPeriodsInfo"-->
                    <!--                    >-->
                    <!--                        Freelancer data is partly publicly used throughout Riviera. E.g. on the-->
                    <!--                        <a href="/people">People page</a>. So be aware of-->
                    <!--                        adding data that should not be visible to the public.-->
                    <!--                        Fields that are private and <strong>not</strong> visible beyond this module are-->
                    <!--                        marked with the <span class="icon f-icon f-icon-eye-crossed"/> icon.-->
                    <!--                    </f-inline-message>-->

                    <f-form-row label="Image">
                        <f-image-input
                            ref="profileImageUploadField"
                            name="image"
                            :src="fetchedData && fetchedData.data.profile_image ? fetchedData.data.profile_image : null"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="First name">
                        <f-text-input
                            name="first name"
                            v-model="fields.first_name"
                            max-width="300px"
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row label="Last name">
                        <f-text-input
                            name="last name"
                            v-model="fields.last_name"
                            max-width="300px"
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Discipline">
                        <f-discipline-dropdown
                            name="discipline"
                            v-model="fields.discipline_id"
                        />
                    </f-form-row>

                    <f-form-row label="Job title">
                        <f-text-input
                            name="job title"
                            v-model="fields.title"
                            max-width="300px"
                            :validation="['required']"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Specialities">
                        <f-custom-chips-input
                            name="specialities"
                            v-model="fields.specialities"
                            icon="f-icon-magic-wand"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Phone number">
                        <f-text-input
                            v-model="fields.phone_number"
                            max-width="250px"
                            pre-icon="telephone2"
                        />
                    </f-form-row>

                    <f-form-row label="Email">
                        <f-text-input
                            v-model="fields.email"
                            name="email"
                            :validation="['email']"
                            max-width="400px"
                            pre-icon="envelope"
                            placeholder="freelancer@gmail.com"
                        />
                    </f-form-row>

                    <f-form-row label="Internal email">
                        <f-text-input
                            v-model="fields.internal_email"
                            name="internal email"
                            :validation="['email']"
                            max-width="400px"
                            pre-icon="envelope"
                            placeholder="name@codedazur.com"
                        />
                    </f-form-row>

                    <f-form-row label="City">
                        <f-text-input
                            v-model="fields.city"
                            max-width="250px"
                            pre-icon="home"
                        />
                    </f-form-row>

                    <f-form-row label="Base office">
                        <f-dropdown
                            v-model="fields.office"
                            :options="officeOptions"
                            :validation="['required']"
                            popup-width="120px"
                            name="office"
                            width="250px"
                            pre-icon="apartment"
                        />
                    </f-form-row>

                    <f-form-row label="Portfolio link">
                        <f-text-input
                            name="portfolio"
                            v-model="fields.portfolio"
                            pre-icon="network"
                            placeholder="https://"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Contact details">
                        <template #label>
                            <icon-private/>
                        </template>
                        <f-editor
                            :toolbar="getEditorToolbar()"
                            v-model="fields.contact_details"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Rate">
                        <template #label>
                            <icon-private/>
                        </template>

                        <f-text-input
                            name="rate"
                            width="100%"
                            max-width="120px"
                            v-model="fields.rate"
                            align="right"
                            :pre-icon="(fields.office === 'austin' ? 'dollar' : 'euro')"
                            :validation="['number']"
                        />
                        &nbsp;
                        <f-checkbox v-model="fields.is_daily_rate" label="Daily rate" style="margin-top: 0;"/>
                    </f-form-row>

                    <f-form-row label="Rating">
                        <template #label>
                            <icon-private/>
                        </template>
                        <f-rating v-model="fields.rating"/>
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Notes">
                        <template #label>
                            <icon-private/>
                        </template>
                        <f-editor
                            :toolbar="getEditorToolbar()"
                            v-model="fields.notes"
                        />
                    </f-form-row>

                    <f-form-row-spacer/>

                    <f-form-row label="Archived">
                        <f-switch v-model="fields.is_archived"/>
                    </f-form-row>
                </section>

                <f-form-placeholder v-else-if="!dataLoaded && loading"/>
            </f-container>

            <skewed-section
                v-if="dataLoaded"
                foreground-color="#333"
                after-background="#222"
                padding="100px 0 150px"
                margin="100px 0 -50px"
            >
                <f-theme-provider variant="dark">
                    <f-container max-width="800px">
                        <f-form-row hide-label>
                            <bullet-point>Working periods</bullet-point>
                            <working-periods-field
                                ref="workingPeriodsField"
                                :periods="fields.periods"
                            />
                        </f-form-row>
                    </f-container>
                </f-theme-provider>
            </skewed-section>

            <entity-action-button
                v-if="dataLoaded"
                icon="floppy-disk"
                :tooltip="(mode === 'edit' ? 'Update' : 'Create')"
                type="submit"
            />
        </f-form>
    </page>
</template>

<script>
import Page from '../Page';
import APIEndpoints from '../../../config/APIEndpoints';
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import FTextInput from '../../../f-components/form-controls/text/FTextInput';
import OfficeOptionsMixin from '../../../mixins/OfficeOptionsMixin';
import FRating from '../../../f-components/form-controls/ratings/FRating';
import FEditor from '../../../f-components/form-controls/editor/FEditor';
import FCustomChipsInput from '../../../f-components/form-controls/chips/FCustomChipsInput';
import APIDataStore from '../../../services/APIDataStore';
import FImageInput from '../../../f-components/form-controls/images/FImageInput';
import WorkingPeriodsField from './components/WorkingPeriodsField';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage';
import IconPrivate from './components/IconPrivate';
import FForm from '../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../f-components/form-controls/forms/FFormRow';
import FFormRowSpacer from '../../../f-components/form-controls/forms/FFormRowSpacer';
import FBreadcrumbs from '../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FDropdown from '../../../f-components/form-controls/dropdowns/FDropdown';
import FDisciplineDropdown from '../../../f-components/form-controls/dropdowns/FDisciplineDropdown';
import {EditorToolbar} from "../../../f-components/form-controls/editor/config";
import EntityCreateEditMixin from '../../../mixins/entities/EntityCreateEditMixin';
import FCheckbox from '../../../f-components/form-controls/checkbox/FCheckbox';
import FSwitch from '../../../f-components/form-controls/switches/FSwitch';
import FContainer from '../../../f-components/layout/container/FContainer';
import SkewedSection from '../../misc/skewed-section/SkewedSection';
import FThemeProvider from '../../../f-components/layout/theme/FThemeProvider';
import FFormPlaceholder from '../../../f-components/loaders/placeholder/FFormPlaceholder';
import BulletPoint from '../../misc/BulletPoint';
import EntityActionButton from '../../misc/entities/EntityActionButton';

export default {
    name: 'freelancer-database-edit-create-page',

    components: {
        EntityActionButton,
        BulletPoint,
        FFormPlaceholder,
        FThemeProvider,
        SkewedSection,
        FContainer,
        FSwitch,
        FCheckbox,
        FDisciplineDropdown,
        FDropdown,
        FBreadcrumb,
        FBreadcrumbs,
        FFormRowSpacer,
        FFormRow,
        FForm,
        Page,
        IconPrivate,
        FInlineMessage,
        WorkingPeriodsField,
        FImageInput,
        FCustomChipsInput,
        FEditor,
        FRating,
        FTextInput,
    },

    mixins: [
        OfficeOptionsMixin,
        EntityCreateEditMixin,
    ],

    data() {
        return {
            documentTitle: (this.$route.name === 'freelancers.edit' ? ['Freelancer Database', 'Edit'] : ['Freelancer Database', 'Create']),
            mode: (this.$route.name === 'freelancers.edit' ? 'edit' : 'create'),
            entityName: 'freelancer',
            profileImageSrc: null,
            fields: {
                first_name: null,
                last_name: null,
                email: null,
                internal_email: null,
                city: null,
                office: null,
                portfolio: null,
                phone_number: null,
                contact_details: '',
                discipline_id: null,
                specialities: [],
                notes: '',
                rate: null,
                is_daily_rate: false,
                rating: null,
                periods: [],
                title: null,
                is_archived: false,
            },
        };
    },

    created() {
        APIDataStore.check(APIDataStoreResources.DEPARTMENTS_AND_DISCIPLINES_COLLECTION);
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.FREELANCER_DATABASE;
        },

        getRedirectPath() {
            return '/freelancers';
        },

        configureFormData(formData) {
            const file = this.$refs.profileImageUploadField.getFile();
            if (file) {
                formData.set('profile_image', file);
            }

            formData.set('periods', JSON.stringify(this.$refs.workingPeriodsField.getData()));

            return formData;
        },

        getEditorToolbar() {
            return [
                EditorToolbar.BOLD,
                EditorToolbar.ITALIC,
                EditorToolbar.HARD_BREAK,
                EditorToolbar.LINK,
                EditorToolbar.STRIKE,
                EditorToolbar.UNDERLINE,
                EditorToolbar.HISTORY,
            ];
        },
    }
}
</script>

<style lang="scss">
.freelancer-database-edit-create-page {
    .f-inline-message {
        margin-bottom: 50px;
    }

    .working-periods-field {
        margin-top: 25px;
    }
}
</style>
