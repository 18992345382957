<template>
    <f-flex
        v-if="!removed"
        class="working-periods-field-item"
        gap="10px"
        cross-axis-align="center"
        main-axis-align="start"
    >
        <f-input-group>
            <f-date-picker
                class-name="working-periods-field-item__from"
                v-model="dStartDate"
                placeholder="Date from"
                :readonly="readonly"
                max-width="160px"
                popup-variant="light"
            />
            <f-date-picker
                class-name="working-periods-field-item__to"
                v-model="dEndDate"
                placeholder="Date to"
                :readonly="readonly"
                :disabled="!dStartDate"
                max-width="160px"
                popup-variant="light"
            />
        </f-input-group>

        <f-text-input
            v-model="dDescription"
            placeholder="Working on"
            :readonly="readonly"
        />

        <f-button-group
            v-if="!readonly"
            class-name="working-periods-field-item__actions"
        >
            <f-icon-button
                icon="minus"
                tabindex="-1"
                class-name="working-periods-field-item__action"
                @click="remove"
                v-tooltip="'Remove period'"
                size="xsmall"
                variant="light"
                outlined
            />
            <f-icon-button
                icon="plus"
                tabindex="-1"
                class-name="working-periods-field-item__action"
                @click="add"
                v-tooltip="'Add period'"
                size="xsmall"
                variant="light"
                outlined
            />
        </f-button-group>
    </f-flex>
</template>

<script>
import FTextInput from '../../../../f-components/form-controls/text/FTextInput';
import {generateUid} from '../../../../utils/generateUid';
import FDatePicker from '../../../../f-components/form-controls/dates/FDatePicker';
import FIconButton from '../../../../f-components/form-controls/buttons/FIconButton';
import EventBus from '../../../../services/EventBus';
import {GlobalEvents} from '../../../../config/Events';
import FInputGroup from '../../../../f-components/form-controls/base/FInputGroup';
import FFlex from '../../../../f-components/layout/flex/FFlex';
import FButtonGroup from '../../../../f-components/form-controls/buttons/FButtonGroup';

export default {
    name: 'working-periods-field-item',

    components: {
        FButtonGroup,
        FFlex,
        FInputGroup,
        FIconButton,
        FDatePicker,
        FTextInput,
    },

    props: {
        id: Number,
        startDate: String,
        endDate: String,
        description: String,
        readonly: Boolean,
    },

    data() {
        return {
            removed: false,
            dDescription: this.description,
            dStartDate: this.startDate,
            dEndDate: this.endDate,
        }
    },

    computed: {
        uid() {
            if (this.id) {
                return this.id;
            }
            return generateUid();
        },
    },

    methods: {
        add() {
            this.$emit('add');
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        remove() {
            this.removed = true;

            this.$emit('remove');
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        getData() {
            if (this.removed || !this.dStartDate) {
                return;
            }

            let data = {
                start_date: this.dStartDate,
                end_date: this.dEndDate,
                description: this.dDescription,
            }

            if (this.id) {
                data['id'] = this.id;
            }

            return data;
        },
    },
}
</script>

<style lang="scss">
.working-periods-field-item.f-flex {
    padding-bottom: 10px;

    @include media(mobile-down) {
        flex-wrap: wrap !important;
        gap: 5px !important;
        padding-bottom: 20px;
    }

    .f-input-group {
        flex: 0 0 320px;
    }

    &__actions {
        opacity: .5;
        transition: opacity .1s;
    }

    &:hover {
        .working-periods-field-item__actions {
            opacity: 1;
        }
    }
}
</style>
