<template>
    <page
        class-name="authentication-redirect-page"
        title="Login"
        :authenticated="false"
        :skewed-header-foreground="null"
        skewed-footer-foreground="#f3f3f3"
        margin-top="0"
    >
        <f-center>
            <div>
                <logo/>
            </div>
        </f-center>
    </page>
</template>

<script>
import Page from '../Page';
import APIEndpoints from '../../../config/APIEndpoints';
import EventBus from '../../../services/EventBus';
import {GlobalEvents} from '../../../config/Events';
import FCenter from '../../../f-components/layout/center/FCenter';
import Logo from '../../misc/Logo';

export default {
    name: 'authentication-g-suite-redirect-page',

    components: {
        Logo,
        FCenter,
        Page,
    },

    mounted() {
        this.login();
    },

    computed: {
        authPage() {
            let authPage = window.localStorage.getItem('riviera.auth.page');
            if (!authPage) {
                authPage = '/auth';
            }
            return authPage;
        },
    },

    data() {
        return {
            token: this.$route.query.token,
            email: this.$route.query.email,
            type: this.$route.name,
        }
    },

    methods: {
        login() {
            window.axios
                .post(APIEndpoints.parse(APIEndpoints.AUTH_LOGIN, {type: this.type}), {
                    email: this.email,
                    token: this.token,
                })
                .then(response => this.onLoginSuccess(response.data))
                .catch((e) => this.onLoginError(e.response.data));
        },

        onLoginSuccess(data) {
            // Set access token
            if (data.access_token) {
                this.$store.commit('accessToken', data.access_token);
            } else {
                console.error('No access token in login success response.');
                EventBus.$emit(GlobalEvents.LOGIN_FAILURE, 'No access token in login success response');

                return this.$router.push({
                    path: this.authPage + '?error=' + encodeURIComponent('An error occurred. Please try again. [No access token in response]'),
                });
            }

            // Update user data in store
            if (data.user) {
                this.$store.commit('$user', data.user);
            } else {
                console.error('No user object in login success response.');
                EventBus.$emit(GlobalEvents.LOGIN_FAILURE, 'No user object in login success response');

                return this.$router.push({
                    path: this.authPage + '?error=' + encodeURIComponent('An error occurred. Please try again. [No user obj in response]'),
                });
            }

            if (data.time) {
                this.$store.commit('time', data.time);
            } else {
                console.error('No time object in login success response.');
                EventBus.$emit(GlobalEvents.LOGIN_FAILURE, 'No time object in login success response');

                return this.$router.push({
                    path: this.authPage + '?error=' + encodeURIComponent('An error occurred. Please try again. [No time object in response]'),
                });
            }

            // Emit event
            EventBus.$emit(GlobalEvents.LOGIN_SUCCESS);

            // Redirect
            if (window.localStorage.getItem('riviera.auth.redirect')) {
                window.location = window.localStorage.getItem('riviera.auth.redirect');
                window.localStorage.removeItem('riviera.auth.redirect');
            } else {
                this.$router.push({
                    name: 'dashboard',
                });
            }
        },

        onLoginError(e) {
            this.$router.push({
                path: this.authPage + '?error=' + (e && e.error ? encodeURIComponent(e.error) : 'Unknown%20error,%20please%20try%20again'),
            });
        },
    },
}
</script>

<style lang="scss">
.page-auth-gsuite {
    // TODO: bit hacky needs refac
    .page-footer__pre {
        padding-top: 0;

        .f-text {
            display: none;
        }
    }
}

.authentication-redirect-page {
    .logo {
        height: 85px;
    }
}
</style>
