<template>
    <page
        class-name="hr-page"
        title="HR"
        :header="`<strong>HR</strong> at code d’azur,<br/>a <em>people-first</em> mentality`"
        :skewed-header-foreground="false"
        pre-max-width="800px"
        skewed-footer-foreground="#f3f3f3"
    >
        <div id="hrPage"/>

        <skewed-section
            background-color="#fff"
            foreground-color="#333"
            class-name="hr-page__skewed-section-a"
        >
            <f-theme-provider variant="dark">
                <timeline max-width="800px" page="hr"/>
            </f-theme-provider>
        </skewed-section>

        <skewed-section class-name="hr-page__skewed-section-b" reversed>
            <f-container max-width="800px" class-name="hr-page__faq">
                <faq
                    render-category-as-bullet-point
                    edit-button-position="bottom"
                    editable
                    edit-button
                    place="hr-page"
                />
            </f-container>
        </skewed-section>

        <page-content-header max-width="1200px">
            <div style="line-height: 105%">
                Be <em>yourself</em>,<br>
                we like <strong>you</strong> that way
            </div>
            <template #post>
                <f-flex direction="column" gap="10px" cross-axis-align="flex-end">
                    <p style="max-width: 350px" class="--align-right">
                        We want to make you feel respected and at home.
                        People first means we have an open culture and your opinion is always valued.
                    </p>
                    <f-button
                        variant="primary"
                        icon="envelope"
                        value="Contact us"
                        :size="(breakpoints.mobile ? 'small' : 'medium')"
                        tag="a"
                        href="mailto:hr@codedazur.com"
                    />
                </f-flex>
            </template>
        </page-content-header>

        <f-container max-width="1200px">
            <div class="hr-page__widget-container">
                <widget title="Onboarding deck" class-name="hr-page__onboarding-deck">
                    <google-drive-embed
                        id="11-pMnyjW--Ui4xWOKOscmkxBS2Cjg_mJrSMfOF_MM54"
                        height-ratio="59%"
                    />
                </widget>

                <div class="hr-page__widget-container__column">
                    <widget title="Life at code d'azur / The finer details">
                        <google-drive-embed
                            id="17_qNjmo-zj4pN0VOZj3GuDuv91Qc2RnH"
                            height-ratio="140%"
                        />
                    </widget>
                </div>

                <div class="hr-page__widget-container__column">
                    <widget title="Relocation guide">
                        <google-drive-embed
                            id="1mGv0HQb9oWytXFKH0cc2CkoxV5CMrShNsWvXQnJoYDA"
                            height-ratio="62%"
                        />
                    </widget>

                    <!--                    <widget title="Policy Flexibility Working From Home">-->
                    <!--                        <google-drive-embed-->
                    <!--                            id="1aeXUspncTqzhjvb_pCtdDhoyJfaHimMe"-->
                    <!--                            height-ratio="140%"-->
                    <!--                        />-->
                    <!--                    </widget>-->
                </div>
            </div>
        </f-container>
    </page>
</template>

<script>
import Page from '../Page';
import FContainer from '../../../f-components/layout/container/FContainer';
import Faq from '../../misc/faq/FAQ';
import SkewedSection from '../../misc/skewed-section/SkewedSection';
import FFlex from '../../../f-components/layout/flex/FFlex';
import PageContentHeader from '../../misc/PageContentHeader';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import Widget from '../../widgets/Widget';
import GoogleDriveEmbed from '../../misc/embeds/GoogleDriveEmbed';
import FFlexItem from '../../../f-components/layout/flex/FFlexItem';
import SecureDocument from './components/SecureDocument';
import BreakpointsMixin from '../../../mixins/BreakpointsMixin';
import FThemeProvider from '../../../f-components/layout/theme/FThemeProvider';
import Timeline from '../../misc/timeline/Timeline';

export default {
    name: 'hr-page',

    mixins: [
        BreakpointsMixin,
    ],

    components: {
        Timeline,
        FThemeProvider,
        SecureDocument,
        FFlexItem,
        GoogleDriveEmbed,
        Widget,
        FButton,
        PageContentHeader,
        FFlex,
        SkewedSection,
        Faq,
        FContainer,
        Page,
    },
}
</script>

<style lang="scss">
.hr-page {
    &__skewed-section-a {
        padding: 80px 0 70px;
        margin: 25px 0 100px;
        z-index: 3;

        @include media(tablet-down) {
            padding: 55px 0;
            margin: 20px 0 60px;
        }

        @include media(mobile-down) {
            padding: 40px 0;
            margin: 15px 0 60px;
        }
    }

    &__skewed-section-b {
        padding: 200px 0 75px 0;
        margin: -200px 0 150px 0;

        @include media(mobile-down) {
            padding: 150px 0 25px 0;
            margin: -150px 0 50px 0;
        }
    }

    &__widget-container {
        margin-top: 100px;
        display: flex;
        gap: 30px;
        flex-wrap: wrap;

        @include media(mobile-down) {
            margin-top: 40px;
        }

        @media only screen and (max-width: 970px) {
            flex-direction: column;
        }

        &__column {
            flex: 1;
            width: 50%;

            @media only screen and (max-width: 970px) {
                width: 100%;
                flex: none;
            }
        }

        .widget-drag {
            display: none;
        }
    }

    &__onboarding-deck {
        flex-basis: 100%;
        padding-bottom: 20px;
    }
}
</style>
