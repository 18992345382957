<template>
    <page
        v-if="hasPermission(Permissions.ADMIN_MISC_VIEW_LOGS)"
        class-name="admin-active-office-clients-page"
        :loading="loading"
        :title="['Admin', 'Active Wifi Office Clients']"
        pre-max-width="800px"
        header="WiFi clients"
    >
        <template #pre>
            <f-breadcrumbs :badge="(fetchedData && fetchedData.items ? fetchedData.items.length : 0)">
                <f-breadcrumb>Admin</f-breadcrumb>
                <f-breadcrumb link="/admin/active-office-clients">
                    Active Wifi Office Clients
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="1000px">
            <f-tabs
                margin
                @tabChange="tabId = $event"
            >
                <f-tab id="clients" title="Clients"/>
                <f-tab id="users" title="Users"/>
            </f-tabs>

            <f-table
                v-if="fetchedData && tabId === 'clients'"
                :empty="items.length === 0"
                ref="table"
                type="simple"
                allow-horizontal-scroll
            >
                <template #head>
                    <f-table-header-row v-if="!fetchedData.error">
                        <f-table-header-cell>Hostname</f-table-header-cell>
                        <f-table-header-cell align="center">Mac</f-table-header-cell>
                        <f-table-header-cell align="center">Last seen</f-table-header-cell>
                    </f-table-header-row>
                </template>
                <f-table-row
                    v-for="row in items"
                    :key="row.mac"
                    no-wrap
                >
                    <f-table-cell>{{ (!!row.hostname ? row.hostname : '-') }}</f-table-cell>
                    <f-table-cell is-id align="center">{{ row.mac }}</f-table-cell>
                    <f-table-cell align="center">{{ row.last_seen }}</f-table-cell>
                </f-table-row>
            </f-table>

            <f-table
                v-if="fetchedData && tabId === 'users' && usersData.length > 0"
                :empty="items.length === 0"
                ref="table"
                type="simple"
                allow-horizontal-scroll
            >
                <template #head>
                    <f-table-header-row v-if="!fetchedData.error">
                        <f-table-header-cell>Name</f-table-header-cell>
                        <f-table-header-cell>Email</f-table-header-cell>
                        <f-table-header-cell align="center">MAC</f-table-header-cell>
                        <f-table-header-cell>Hostname</f-table-header-cell>
                        <f-table-header-cell align="center">Last seen</f-table-header-cell>
                    </f-table-header-row>
                </template>
                <f-table-row
                    v-for="row in usersData"
                    :key="row.id"
                    no-wrap
                >
                    <f-table-cell>{{ row.name }}</f-table-cell>
                    <f-table-cell>{{ row.email }}</f-table-cell>
                    <f-table-cell is-id align="center">{{ row?.unify_user?.mac }}</f-table-cell>
                    <f-table-cell>{{ row?.unify_user?.hostname }}</f-table-cell>
                    <f-table-cell align="center">{{ row.last_seen }}</f-table-cell>
                </f-table-row>
            </f-table>

            <f-placeholder-container v-if="!fetchedData && loading">
                <f-placeholder width="100%" height="60px"/>
                <f-placeholder width="100%" height="60px"/>
                <f-placeholder width="100%" height="60px"/>
            </f-placeholder-container>

            <f-inline-message
                v-if="fetchedData && fetchedData.error"
                id="logError"
                :closable="false"
                variant="error"
            >
                {{ fetchedData.error }}
            </f-inline-message>
        </f-container>
    </page>
</template>

<script>
import APIEndpoints from '../../../../config/APIEndpoints';
import EntityPaginatedFilteredOverviewMixin from '../../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import Page from '../../Page';
import FTable from '../../../../f-components/data/table/FTable';
import FPlaceholderContainer from '../../../../f-components/loaders/placeholder/FPlaceholderContainer';
import FPlaceholder from '../../../../f-components/loaders/placeholder/FPlaceholder';
import FTableRow from '../../../../f-components/data/table/FTableRow';
import FTableCell from '../../../../f-components/data/table/FTableCell';
import FTableHeaderCell from '../../../../f-components/data/table/FTableHeaderCell';
import FInlineMessage from '../../../../f-components/notice/inline-message/FInlineMessage';
import FBreadcrumbs from '../../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FContainer from '../../../../f-components/layout/container/FContainer';
import FTableHeaderRow from '../../../../f-components/data/table/FTableHeaderRow';
import FTab from '../../../../f-components/navigation/tabs/FTab.vue';
import FTabs from '../../../../f-components/navigation/tabs/FTabs.vue';

export default {
    name: 'admin-active-office-clients-page',

    components: {
        FTabs,
        FTab,
        FTableHeaderRow,
        FContainer,
        FBreadcrumb,
        FBreadcrumbs,
        FInlineMessage,
        FTableHeaderCell,
        FTableCell,
        FTableRow,
        FPlaceholder,
        FPlaceholderContainer,
        FTable,
        Page,
    },

    props: {
        paginated: {
            type: Boolean,
            default: false,
        },
    },

    mixins: [
        EntityPaginatedFilteredOverviewMixin,
    ],

    methods: {
        getEndpoint() {
            return APIEndpoints.ADMIN_ACTIVE_OFFICE_CLIENTS;
        },
        onFetchItemsCompleteCustom() {
            this.usersData = this.fetchedData?.data_users ?? [];
            console.log(this.usersData);
        },
    },

    data() {
        return {
            usersData: [],
            tabId: 'clients',
        }
    },
}
</script>
