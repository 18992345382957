<template>
    <page
        class-name="faq-page"
        title="FAQs & Guides"
        skewed-footer-foreground="#fff"
        header="What would <strong>you</strong><br>like to <em>know</em>?"
        pre-max-width="800px"
    >
        <f-container max-width="800px" style="padding-bottom: 40px">
            <f-inline-message
                id="faqRivieraDead"
                class-name="dashboard-page__riviera-dead"
            >
                This FAQ is still applicable, even though we are heading towards One Brand.
            </f-inline-message>
        </f-container>
        <div id="faqPage"/>
        <f-container max-width="800px">
            <faq
                editable
                search
                place="faq-page"
            />
        </f-container>
    </page>
</template>

<script>
import Page from '../Page';
import FContainer from '../../../f-components/layout/container/FContainer';
import Faq from '../../misc/faq/FAQ';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage.vue';
import FButton from '../../../f-components/form-controls/buttons/FButton.vue';

export default {
    name: 'faq-page',

    components: {
        FButton,
        FInlineMessage,
        Faq,
        FContainer,
        Page,
    },
}
</script>

<style lang="scss">
.faq-page {
    &__edit {
        @include media(mobile-down) {
            display: none;
        }
    }

    .slack-icon {
        width: 18px;
        height: 18px;
        background: url(../../../img/slack.png);
        background-size: cover;
        margin-right: 10px;
    }
}
</style>
