<template>
    <f-table-cell class-name="f-table-contact-cell">
        <div v-if="internalEmail" class="f-table-contact-cell__item">
            <f-icon icon="envelope"/>
            <a
                @click.prevent="open($event, `mailto:${internalEmail}`)"
                :href="`mailto:${internalEmail}`"
                v-text="internalEmail"
                target="_blank"
            />
        </div>
        <div v-if="email && email !== internalEmail" class="f-table-contact-cell__item">
            <f-icon icon="envelope"/>
            <a
                @click.prevent="open($event, `mailto:${email}`)"
                :href="`mailto:${email}`"
                v-text="email"
                target="_blank"
            />
        </div>
        <div v-if="(phone && phone !== '?' && phone !== '')" class="f-table-contact-cell__item">
            <f-icon icon="telephone2"/>
            <a
                @click.prevent="open($event, `tel:${phone}`)"
                :href="`tel:${phone}`"
                v-text="phone"
                target="_blank"
            />
        </div>
    </f-table-cell>
</template>

<script>
import FTableCell from './FTableCell';
import FIcon from '../../layout/icons/FIcon';

export default {
    name: 'f-table-contact-cell',

    components: {
        FIcon,
        FTableCell,
    },

    props: {
        phone: String,
        email: String,
        internalEmail: String,
    },

    methods: {
        open(e, uri) {
            e.stopPropagation();
            window.open(uri);
        },
    },
}
</script>

<style lang="scss">
.f-table-contact-cell {
    &__item {
        white-space: nowrap;
        padding: 2px 0;

        a {
            text-decoration: none;
            padding-left: 5px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
