import ErrorHandler from '../../services/ErrorHandler';
import {cleanObj} from '../../utils/cleanObject';
import LoadingMixin from '../LoadingMixin';
import {SiteLoaderSizes} from '../../f-components/loaders/site-loader/config';

/**
 * Helper mixin for non-API data store overview data.
 */
export default {
    mixins: [
        LoadingMixin,
    ],

    props: {
        paginated: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            filterTimeOut: null,
            fetchedData: null,
            allowFilterChange: true,
        };
    },

    computed: {
        items() {
            return (this.fetchedData && this.fetchedData.data ? this.fetchedData.data : []);
        },
        activeFilters() {
            return (this.fetchedData ? this.fetchedData.filters : null);
        },
        currentPage: {
            get() {
                const previousFilters = this.getPreviousFiltersAndPagination(),
                    previousPage = (previousFilters.page ? previousFilters.page : 1);

                return (this.fetchedData ? this.fetchedData.current_page : previousPage);
            },
            set(val) {
                this.fetchedData.current_page = val;
            },
        },
        lastPage() {
            return (this.fetchedData ? this.fetchedData.last_page : 1);
        },
    },

    mounted() {
        this.applyPreviousFiltersAndPagination();
        this.fetchItems();
        this.$on('refresh', () => this.fetchItems(this.currentPage));
    },

    methods: {
        applyPreviousFiltersAndPagination() {
            this.allowFilterChange = false;

            const filters = this.getPreviousFiltersAndPagination();
            if (filters.page) {
                delete filters.page;
            }

            this.applyPreviousFilters(filters);

            this.$nextTick(() => this.allowFilterChange = true);
        },

        applyPreviousFilters(filters) {
            filters;
        },

        savePreviousFiltersAndPagination() {
            window.localStorage.setItem('riviera.previous-filters', JSON.stringify({
                ...this.getAllPreviousFilters(),
                ...{
                    [this.$router.currentRoute.name]: this.getFiltersAndPaginationParams(),
                },
            }));
        },

        getAllPreviousFilters() {
            let filters = window.localStorage.getItem('riviera.previous-filters');

            if (filters) {
                try {
                    filters = JSON.parse(filters);
                } catch (error) {
                    console.warn(error);
                }
            }

            if (!filters || typeof filters !== 'object') {
                filters = {};
            }

            return filters;
        },

        getPreviousFiltersAndPagination() {
            const filters = this.getAllPreviousFilters();

            return filters[this.$router.currentRoute.name] ? filters[this.$router.currentRoute.name] : {};
        },

        getEndpoint() {
            throw new Error('Implement getEndpoint');
        },

        onPaginationNavigate(i, position, scrollToElement) {
            if (!scrollToElement && this.$refs.table) {
                scrollToElement = this.$refs.table.$el;
            }

            if (position === 'bottom' && scrollToElement) {
                scrollToElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }

            this.fetchItems(i);
        },

        onFilterChange() {
            if (!this.allowFilterChange) {
                return;
            }

            window.clearTimeout(this.filterTimeOut);

            this.filterTimeOut = window.setTimeout(() => {
                if (!this.loading) this.applyFilter();
            }, 500);
        },

        getFilters() {
            return [];
        },

        applyFilter() {
            this.fetchItems(1);
        },

        getFiltersAndPaginationParams(pageNumber) {
            const pageParams = {};
            if (this.paginated) {
                pageParams.page = (pageNumber ? pageNumber : this.currentPage);
            }

            return cleanObj({
                ...pageParams,
                ...this.getFilters(),
            });
        },

        fetchItems(pageNumber) {
            this.startLoading(SiteLoaderSizes.BIG);

            window.axios
                .get(`${this.getEndpoint()}?${new URLSearchParams(this.getFiltersAndPaginationParams(pageNumber))}`)
                .then(response => this.onFetchItemsComplete(response))
                .catch(error => this.onError(error));
        },

        onFetchItemsComplete(response) {
            this.endLoading(true);
            this.fetchedData = response.data;

            this.onFetchItemsCompleteCustom();

            if (this.paginated && this.fetchedData.current_page > this.fetchedData.last_page) {
                this.fetchItems(1);
                return;
            }

            this.savePreviousFiltersAndPagination();
        },

        onFetchItemsCompleteCustom() {
            //
        },

        onError(error) {
            this.endLoading();
            ErrorHandler.onGeneralError(error);
        },
    },
}
