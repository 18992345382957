<template>
    <widget
        v-if="active && employees.length > 0"
        class-name="currently-at-office-widget"
        :badge="$store.state.$currentlyAtOffice.length"
        enabled-setting="widgets.currently_at_office.enabled"
        extra-break-points
        header="Currently at Amsterdam office"
    >
        <widget-user-list ref="list">
            <widget-user-list-item
                v-for="(user, i) in $store.state.$currentlyAtOffice"
                :key="i"
            >
                <user-card-compact
                    :user="user"
                    full-width
                />
            </widget-user-list-item>
        </widget-user-list>
    </widget>
</template>

<script>
import APIDataStoreResources from '../../../config/APIDataStoreResources';
import APIDataStore from '../../../services/APIDataStore';
import UserHelperMixin from '../../../mixins/UserHelperMixin';
import Widget from '../Widget';
import UserCardCompact from "../../cards/users/UserCardCompact";
import WidgetUserList from '../../misc/widget-user-list/WidgetUserList';
import WidgetUserListItem from '../../misc/widget-user-list/WidgetUserListItem';

export default {
    name: 'currently-at-office-widget',

    components: {
        WidgetUserListItem,
        WidgetUserList,
        UserCardCompact,
        Widget,
    },

    computed: {
        active() {
            return ['amsterdam'].includes(this.$store.state.$user.office);
        },
        employees() {
            if (this.$store.state.$currentlyAtOffice && this.$store.state.$currentlyAtOffice.length > 0) {
                return this.$store.state.$currentlyAtOffice;
            }
            return [];
        },
    },

    watch: {
        employees() {
            this.$nextTick(() => {
                if (this.$refs.list) {
                    this.$refs.list.$refs.scrollPane.invalidate();
                }
            });
        },
    },

    mixins: [
        UserHelperMixin,
    ],

    created() {
        if (this.active) {
            APIDataStore.check(APIDataStoreResources.USERS_CURRENTLY_AT_OFFICE);
        }
    },
}
</script>
