<template>
    <page
        class-name="freelancer-database-show-page"
        :loading="loading && !fetchedData"
        :title="['Freelancer Database', 'View']"
        header="View freelancer"
        :skewed-footer-foreground="(!fetchedData && loading ? '#fff' : null)"
        :actions="actions"
        stretch-first-container
        pre-max-width="800px"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb link="/freelancers">
                    Freelancer Database
                </f-breadcrumb>
                <f-breadcrumb :loading="!!!fetchedData">
                    {{ (fetchedData ? `${fields.first_name} ${fields.last_name}` : '') }}
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="800px">
            <entity-header
                v-if="fetchedData"
                :image="fields.profile_image"
                :name="fields.first_name + ' ' + fields.last_name"
                :title="fields.first_name + ' ' + fields.last_name"
            >
                <department-discipline-path
                    :discipline="fields.discipline"
                    :office="fields.office"
                    discipline-on-new-line
                />

                <template #post>
                    <div>
                        <f-flex direction="column" cross-axis-align="end">
                            <f-rating
                                v-model="fields.rating"
                                readonly
                            />
                            <f-chip
                                v-if="fields.is_archived"
                                variant="archived"
                            >
                                archived
                            </f-chip>
                        </f-flex>
                    </div>
                </template>
            </entity-header>

            <f-form v-if="fetchedData">
                <f-form-row label="Job title" v-if="fields.title">
                    <f-text-input
                        v-model="fields.title"
                        max-width="300px"
                        readonly
                    />
                </f-form-row>

                <f-form-row-spacer v-if="fields.title"/>

                <f-form-row label="Phone number" v-if="fields.phone_number">
                    <f-text-input
                        v-model="fields.phone_number"
                        max-width="250px"
                        pre-icon="telephone2"
                        readonly
                    />
                </f-form-row>

                <f-form-row label="Email" v-if="fields.email">
                    <f-text-input
                        v-model="fields.email"
                        max-width="400px"
                        pre-icon="envelope"
                        readonly
                    />
                </f-form-row>

                <f-form-row label="Internal email" v-if="fields.internal_email">
                    <f-text-input
                        v-model="fields.internal_email"
                        max-width="400px"
                        pre-icon="envelope"
                        readonly
                    />
                </f-form-row>

                <f-form-row-spacer/>

                <f-form-row label="City">
                    <f-text-input
                        v-model="fields.city"
                        max-width="250px"
                        pre-icon="home"
                        readonly
                    />
                </f-form-row>

                <f-form-row label="Base office">
                    <f-dropdown
                        v-model="fields.office"
                        :options="officeOptions"
                        width="250px"
                        pre-icon="apartment"
                        readonly
                    />
                </f-form-row>

                <f-form-row label="Portfolio link" v-if="fields.portfolio">
                    <f-text-input
                        name="portfolio"
                        v-model="fields.portfolio"
                        pre-icon="network"
                        readonly
                    />
                </f-form-row>

                <f-form-row-spacer/>

                <f-form-row
                    v-if="fields.contact_details && fields.contact_details !== '<p></p>'"
                    label="Contact details"
                >
                    <f-editor
                        ref="contactDetailsEditor"
                        v-model="fields.contact_details"
                        readonly
                    />
                </f-form-row>

                <f-form-row-spacer/>

                <f-form-row label="Specialities" v-if="fields.specialities">
                    <f-custom-chips-input
                        name="specialities"
                        v-model="fields.specialities"
                        icon="f-icon-magic-wand"
                        readonly
                    />
                </f-form-row>

                <f-form-row-spacer/>

                <f-form-row :label="fields.is_daily_rate ? 'Daily rate' : 'Hourly rate'">
                    <f-text-input
                        name="rate"
                        width="100%"
                        max-width="120px"
                        v-model="fields.rate"
                        align="right"
                        :pre-icon="(fields.office === 'austin' ? 'dollar' : 'euro')"
                        readonly
                    />
                </f-form-row>

                <f-form-row
                    v-if="fields.notes && fields.notes !== '<p></p>'"
                    label="Notes"
                >
                    <f-editor
                        ref="notesEditor"
                        v-model="fields.notes"
                        readonly
                    />
                </f-form-row>
            </f-form>

            <div v-else-if="!fetchedData && loading">
                <f-entity-header-placeholder disable-rows/>
                <br><br>
                <f-form-placeholder/>
            </div>
        </f-container>

        <skewed-section
            v-if="fetchedData"
            foreground-color="#333"
            after-background="#222"
            padding="100px 0 150px"
            margin="100px 0 -50px"
        >
            <f-theme-provider variant="dark">
                <f-container max-width="800px">
                    <f-tabs
                        margin
                        @tabChange="tabId = $event"
                    >
                        <f-tab id="working-periods" title="Working periods"/>
                        <f-tab id="references" title="References"/>
                    </f-tabs>

                    <div v-if="tabId === 'working-periods'">
                        <working-periods-field
                            v-if="fetchedData && fields.periods && fields.periods.length > 0"
                            ref="workingPeriodsField"
                            :periods="fields.periods"
                            readonly
                        />
                        <nothing-found v-else name="working periods"/>
                    </div>

                    <div v-if="tabId === 'references'">
                        <references-list :references="fields.references"/>
                    </div>
                </f-container>
            </f-theme-provider>
        </skewed-section>

        <entity-action-button
            :permission="Permissions.FREELANCERS_CREATE"
            :route="`/freelancers/edit/${$route.params.id}`"
            icon="pencil"
            tooltip="Edit"
        />
    </page>
</template>

<script>
import APIEndpoints from '../../../config/APIEndpoints';
import FTextInput from '../../../f-components/form-controls/text/FTextInput';
import OfficeOptionsMixin from '../../../mixins/OfficeOptionsMixin';
import FRating from '../../../f-components/form-controls/ratings/FRating';
import FEditor from '../../../f-components/form-controls/editor/FEditor';
import FCustomChipsInput from '../../../f-components/form-controls/chips/FCustomChipsInput';
import WorkingPeriodsField from './components/WorkingPeriodsField';
import Page from '../Page';
import FForm from '../../../f-components/form-controls/forms/FForm';
import FFormRow from '../../../f-components/form-controls/forms/FFormRow';
import FFormRowSpacer from '../../../f-components/form-controls/forms/FFormRowSpacer';
import FBreadcrumbs from '../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import FDropdown from '../../../f-components/form-controls/dropdowns/FDropdown';
import EntityHeader from '../../misc/entities/EntityHeader';
import DepartmentDisciplinePath from '../../misc/department-discipline-path/DepartmentDisciplinePath';
import EntityShowMixin from '../../../mixins/entities/EntityShowMixin';
import FChip from '../../../f-components/data/chip/FChip';
import FreelancerMixin from './FreelancerMixin';
import FContainer from '../../../f-components/layout/container/FContainer';
import FEntityHeaderPlaceholder from '../../../f-components/loaders/placeholder/FEntityHeaderPlaceholder';
import FFormPlaceholder from '../../../f-components/loaders/placeholder/FFormPlaceholder';
import SkewedSection from '../../misc/skewed-section/SkewedSection';
import FTabs from '../../../f-components/navigation/tabs/FTabs';
import FTab from '../../../f-components/navigation/tabs/FTab';
import FThemeProvider from '../../../f-components/layout/theme/FThemeProvider';
import NothingFound from '../../misc/NothingFound';
import {Permissions} from '../../../config/Permissions';
import ReferencesList from '../../misc/ReferencesList';
import FFlex from '../../../f-components/layout/flex/FFlex';
import EntityActionButton from '../../misc/entities/EntityActionButton';

export default {
    name: 'freelancer-database-show-page',

    components: {
        EntityActionButton,
        FFlex,
        ReferencesList,
        NothingFound,
        FThemeProvider,
        FTab,
        FTabs,
        SkewedSection,
        FFormPlaceholder,
        FEntityHeaderPlaceholder,
        FContainer,
        FChip,
        DepartmentDisciplinePath,
        EntityHeader,
        FDropdown,
        FBreadcrumb,
        FBreadcrumbs,
        FFormRowSpacer,
        FFormRow,
        FForm,
        Page,
        WorkingPeriodsField,
        FCustomChipsInput,
        FEditor,
        FRating,
        FTextInput,
    },

    mixins: [
        OfficeOptionsMixin,
        EntityShowMixin,
        FreelancerMixin,
    ],

    data() {
        return {
            entityName: 'freelancer',
            tabId: null,
        }
    },

    computed: {
        actions() {
            if (!this.fetchedData) {
                return [];
            }

            let actions = [];

            if (this.hasPermission(Permissions.FREELANCERS_CREATE)) {
                actions.push({
                    value: 'Edit freelancer',
                    icon: 'pencil',
                    link: `/freelancers/edit/${this.$route.params.id}`,
                });

                actions.push({
                    value: (this.fields.is_archived ? 'Remove from archive' : 'Archive freelancer'),
                    icon: 'archive',
                    onClick: () => {
                        this.setArchived(this.$route.params.id, !this.fields.is_archived);
                        this.$refs.actions.close();
                    },
                });

                actions.push({
                    value: 'Delete freelancer',
                    icon: 'trash2',
                    onClick: () => {
                        this.deleteFreelancer(this.$route.params.id, this.fields.name);
                        this.$refs.actions.close();
                    },
                });
            }

            return actions;
        },
    },

    methods: {
        getBaseUrl() {
            return APIEndpoints.FREELANCER_DATABASE;
        },
    }
}
</script>

<style lang="scss">
.freelancer-database-show-page {
}
</style>
