<template>
    <navigation-item
        :class-name="[
            'admin-item',
             (popupOpen ? '--open' : null),
        ]"
        icon="cog"
        title="Admin"
        :force-hover="popupOpen"
        @click="onClick"
    >
        <f-popup
            v-if="popupOpen"
            @close="popupOpen = false"
            :target="$el"
            padding="0"
        >
            <f-popup-list max-height="500px" scroll-variant="light">
                <f-popup-list-item
                    v-if="hasPermission(Permissions.ADMIN_USERS_VIEW)"
                    link="/admin/users"
                    icon="users2"
                    @click="onItemClick"
                >
                    Users
                </f-popup-list-item>

                <!--                <f-popup-list-item-->
                <!--                    v-if="hasPermission(Permissions.ADMIN_GALLERIES)"-->
                <!--                    icon="pictures"-->
                <!--                    @click="onItemClick"-->
                <!--                    link="/admin/galleries"-->
                <!--                >-->
                <!--                    Picture galleries-->
                <!--                </f-popup-list-item>-->
                <!--                <f-popup-list-item-->
                <!--                    v-if="hasPermission(Permissions.ADMIN_FIKA)"-->
                <!--                    icon="presentation"-->
                <!--                    @click="onItemClick"-->
                <!--                    link="/admin/fika"-->
                <!--                >-->
                <!--                    FIKAs-->
                <!--                </f-popup-list-item>-->
                <!--                <f-popup-list-item-->
                <!--                    v-if="hasPermission(Permissions.ADMIN_ANNOUNCEMENTS)"-->
                <!--                    icon="bullhorn"-->
                <!--                    @click="onItemClick"-->
                <!--                    link="/admin/announcements"-->
                <!--                >-->
                <!--                    Announcements-->
                <!--                </f-popup-list-item>-->
                <!--                <f-popup-list-item-->
                <!--                    v-if="hasPermission(Permissions.ADMIN_PAGES)"-->
                <!--                    icon="layers"-->
                <!--                    @click="onItemClick"-->
                <!--                    link="/admin/pages"-->
                <!--                >-->
                <!--                    Pages-->
                <!--                </f-popup-list-item>-->

                <f-popup-list-item
                    v-if="hasPermission(Permissions.ADMIN_MISC_FLUSH_CACHE)"
                    icon="database-refresh"
                    @click="onFlushCacheClick"
                >
                    Flush API cache
                </f-popup-list-item>
                <f-popup-list-item
                    v-if="hasPermission(Permissions.ADMIN_MISC_EXTERNAL_CONNECTIONS)"
                    icon="satellite2"
                    @click="onOpenExternalConnectionsClick"
                >
                    Manage external<br> connections
                </f-popup-list-item>
                <f-popup-list-item
                    v-if="hasPermission(Permissions.ADMIN_MISC_VIEW_LOGS)"
                    icon="file-search"
                    @click="onItemClick"
                    link="/admin/api-logs"
                >
                    API logs
                </f-popup-list-item>
                <f-popup-list-item
                    v-if="hasPermission(Permissions.ADMIN_MISC_VIEW_LOGS)"
                    icon="laptop-phone"
                    @click="onItemClick"
                    link="/admin/active-office-clients"
                >
                    Active office WiFi<br> clients
                </f-popup-list-item>

                <!--                <f-popup-list-item-->
                <!--                    v-if="hasPermission(Permissions.ADMIN_CLIENTS_VIEW)"-->
                <!--                    icon="briefcase"-->
                <!--                    link="/admin/clients"-->
                <!--                    @click="onItemClick"-->
                <!--                >-->
                <!--                    Clients <sup>DEPRECATED</sup>-->
                <!--                </f-popup-list-item>-->
                <!--                <f-popup-list-item-->
                <!--                    v-if="hasPermission(Permissions.ADMIN_PRODUCTS_VIEW)"-->
                <!--                    icon="receipt"-->
                <!--                    link="/admin/products"-->
                <!--                    @click="onItemClick"-->
                <!--                >-->
                <!--                    Rate cards <sup>DEPRECATED</sup>-->
                <!--                </f-popup-list-item>-->
                <!--                <f-popup-list-item-->
                <!--                    v-if="hasPermission(Permissions.ADMIN_DISCIPLINES_VIEW)"-->
                <!--                    icon="tags"-->
                <!--                    link="/admin/disciplines"-->
                <!--                    @click="onItemClick"-->
                <!--                >-->
                <!--                    Disciplines <sup>DEPRECATED</sup>-->
                <!--                </f-popup-list-item>-->
            </f-popup-list>
        </f-popup>

        <f-modal
            v-if="externalConnectionsModalOpen"
            title="Manage external connections"
            width="500px"
            class-name="admin-item__external-connections-popup"
            @close="externalConnectionsModalOpen = false"
        >
            <!--            <f-button-->
            <!--                value="Connect Exact"-->
            <!--                icon="link"-->
            <!--                @click="externalConnect('exact')"-->
            <!--            />-->
            <f-button
                value="Connect Slack"
                icon="link"
                @click="externalConnect('slack')"
            />
        </f-modal>
    </navigation-item>
</template>

<script>
import FPopup from '../../../../../f-components/other/popup/FPopup';
import FPopupList from '../../../../../f-components/other/popup/FPopupList';
import FPopupListItem from '../../../../../f-components/other/popup/FPopupListItem';
import FModal from '../../../../../f-components/other/modal/FModal';
import FButton from '../../../../../f-components/form-controls/buttons/FButton';
import APIEndpoints from '../../../../../config/APIEndpoints';
import EventBus from '../../../../../services/EventBus';
import {GlobalEvents} from '../../../../../config/Events';
import NavigationItem from './NavigationItem';

export default {
    name: 'admin-item',

    components: {
        NavigationItem,
        FButton,
        FModal,
        FPopupListItem,
        FPopupList,
        FPopup,
    },

    data() {
        return {
            popupOpen: false,
            externalConnectionsModalOpen: false,
        }
    },

    methods: {
        onClick() {
            this.popupOpen = true;
            EventBus.$emit(GlobalEvents.TOOLTIP_HIDE);
        },

        onItemClick() {
            this.popupOpen = false;
        },

        onOpenExternalConnectionsClick() {
            this.externalConnectionsModalOpen = true;
            this.$nextTick(() => this.popupOpen = false);
        },

        onFlushCacheClick() {
            this.onItemClick();

            EventBus.$emit(
                GlobalEvents.ALERT_SHOW,
                {
                    title: 'Flush cache',
                    body: 'Are you sure you want to flush the API cache?',
                    cancelButton: true,
                    confirmButtonText: 'Flush',
                    confirmButtonVariant: 'danger',
                    onConfirm: () => {
                        window.open(APIEndpoints.secureRedirect(APIEndpoints.ADMIN_FLUSH_CACHE));
                    },
                },
            );
        },

        externalConnect(provider) {
            this.externalConnectionsModalOpen = false;
            window.open(APIEndpoints.secureRedirect(APIEndpoints.parse(APIEndpoints.ADMIN_EXTERNAL_CONNECTIONS, {provider: provider})));
        },
    },
}
</script>

<style lang="scss">
.admin-item {
    &.--open {
        pointer-events: none;
    }

    &__external-connections-popup {
        .f-button {
            margin: 5px;
        }
    }
}

</style>
