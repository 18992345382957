<template>
    <page
        class-name="freelancers-database-page"
        title="Freelancer database"
        header="Freelancers"
        pre-max-width="800px"
    >
        <template #pre>
            <f-breadcrumbs>
                <f-breadcrumb>
                    Freelancer database
                </f-breadcrumb>
                <f-breadcrumb>
                    Overview
                </f-breadcrumb>
            </f-breadcrumbs>
        </template>

        <f-container max-width="1200px">
            <!--            <f-inline-message id="rivieraMeetingRoomsDead" :closable="false" variant="warning">-->
            <!--                The Freelancers module has been discontinued. Because we are heading towards One Brand, and-->
            <!--                the recent switch to Microsoft Office 365.-->
            <!--            </f-inline-message>-->


            <f-filter
                show-active-filters
                :active-filters="activeFilters"
                :filter-casts="{
                    query: 'query',
                    currently_working: 'boolean',
                    archived: 'boolean'
                }"
            >
                <f-filter-row>
                    <f-filter-item>
                        <f-text-input
                            placeholder="Search…"
                            pre-icon="magnifier"
                            v-model="searchQuery"
                            class-name="--align-center"
                            max-width="300px"
                            resetable
                            @empty="searchQuery = ''"
                        />
                    </f-filter-item>
                    <f-filter-item>
                        <f-dropdown
                            v-model="office"
                            :options="officeOptions"
                            popup-width="120px"
                            placeholder="Office"
                            pre-icon="apartment"
                            resetable
                        />
                    </f-filter-item>
                </f-filter-row>
                <f-filter-row>
                    <f-filter-item class-name="filter-switch">
                        <f-switch v-model="currentlyWorking" id="currentlyWorking"/>
                        <label for="currentlyWorking" class="--no-select">Currently working</label>
                    </f-filter-item>
                    <f-filter-item class-name="filter-switch">
                        <f-switch v-model="archived" id="archiveSwitch"/>
                        <label for="archiveSwitch" class="--no-select">Archived</label>
                    </f-filter-item>
                </f-filter-row>
            </f-filter>

            <f-table
                v-if="fetchedData"
                :empty="(fetchedData && items.length === 0)"
                ref="table"
                rows-hoverable
            >
                <template #pre>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                    />
                </template>

                <template #head>
                    <f-table-header-row>
                        <f-table-header-cell colspan="2">
                            Freelancer
                        </f-table-header-cell>
                        <f-table-header-cell>
                            Department, discipline & specialities
                        </f-table-header-cell>
                        <f-table-header-cell>
                            Contact
                        </f-table-header-cell>
                        <f-table-header-cell align="center">
                            Rating
                        </f-table-header-cell>
                        <f-table-header-cell align="right">
                            Rate
                        </f-table-header-cell>
                        <f-table-header-cell/>
                    </f-table-header-row>
                </template>

                <f-table-row
                    v-for="row in items"
                    :key="row.id"
                    :actions="[
                                    {
                                        value: 'View',
                                        icon: 'magnifier',
                                        onClick: () => $router.push(`/freelancers/${row.id}`),
                                    },
                                    (hasPermission(Permissions.FREELANCERS_CREATE) ? {
                                        value: 'Edit',
                                        icon: 'pencil',
                                        onClick: () => $router.push(`/freelancers/edit/${row.id}`),
                                    } : null),
                                    (hasPermission(Permissions.FREELANCERS_CREATE) ? {
                                        icon: 'archive',
                                        value: (!row.is_archived ? 'Archive' : 'Remove from archive'),
                                        onClick: () => setArchived(row.id, !row.is_archived),
                                    } : null),
                                    (hasPermission(Permissions.FREELANCERS_DELETE) ? {
                                        icon: 'trash2',
                                        value: 'Delete',
                                        onClick: () => deleteFreelancer(row.id, row.name),
                                    } : null),
                                ]"
                    @click="$router.push(`/freelancers/${row.id}`)"
                >
                    <f-table-cell width="50px" no-padding-right>
                        <entity-avatar
                            size="large"
                            :src="row.profile_image"
                            :name="row.name"
                        />
                    </f-table-cell>
                    <f-table-title-cell class-name="--no-wrap">
                        {{ row.name }}
                        <template #sub v-if="row.title || row.current_period">
                            {{ row.title }}

                            <div
                                v-if="row.current_period"
                                class="freelancers-database-page__working-on"
                            >
                                <f-chip-group>
                                    <f-chip size="small">Working on</f-chip>
                                    <f-chip size="small" variant="dark">
                                        {{ row.current_period.description }}
                                    </f-chip>
                                </f-chip-group>
                            </div>
                            <div v-if="row.is_archived">
                                <f-chip variant="archived" size="small">archived</f-chip>
                            </div>
                        </template>
                    </f-table-title-cell>
                    <f-table-cell>
                        <department-discipline-path
                            :discipline="row.discipline"
                            :office="row.office"
                            discipline-on-new-line
                        />
                        <div
                            v-if="row.specialities && row.specialities.length > 0"
                            class="freelancers-database-page__specialities"
                        >
                            <f-chip
                                v-for="(speciality, i) in row.specialities"
                                :key="i"
                                size="small"
                                variant="status"
                            >
                                {{ speciality }}
                            </f-chip>
                        </div>
                    </f-table-cell>
                    <f-table-contact-cell
                        :email="row.email"
                        :internal-email="row.internal_email"
                        :phone="row.phone_number_visual"
                    />
                    <f-table-cell align="center">
                        <f-rating
                            :value="row.rating"
                            readonly
                        />
                    </f-table-cell>
                    <f-table-cell align="right" no-wrap>
                        {{
                            (row.rate ? (formatPrice(row.rate) + (row.is_daily_rate ? '/d' : '/h')) : '')
                        }}
                    </f-table-cell>
                </f-table-row>

                <template #post>
                    <f-pagination
                        v-if="fetchedData"
                        :current-page="currentPage"
                        :page-count="lastPage"
                        @navigate="onPaginationNavigate"
                        bottom
                    />
                </template>
            </f-table>

            <f-table-placeholder v-else-if="!fetchedData && loading"/>
        </f-container>

        <entity-action-button
            :permission="Permissions.FREELANCERS_CREATE"
            route="/freelancers/create"
        />
    </page>
</template>

<script>
import APIEndpoints from '../../../config/APIEndpoints';
import EntityPaginatedFilteredOverviewMixin from '../../../mixins/entities/EntityPaginatedFilteredOverviewMixin';
import Page from '../Page';
import FFilter from '../../../f-components/data/filter/FFilter';
import FFilterRow from '../../../f-components/data/filter/FFilterRow';
import FFilterItem from '../../../f-components/data/filter/FFilterItem';
import FTextInput from '../../../f-components/form-controls/text/FTextInput';
import FDropdown from '../../../f-components/form-controls/dropdowns/FDropdown';
import OfficeOptionsMixin from '../../../mixins/OfficeOptionsMixin';
import FTable from '../../../f-components/data/table/FTable';
import FTableRow from '../../../f-components/data/table/FTableRow';
import FTableHeaderCell from '../../../f-components/data/table/FTableHeaderCell';
import FTableCell from '../../../f-components/data/table/FTableCell';
import EntityAvatar from '../../misc/entities/EntityAvatar';
import FTableTitleCell from '../../../f-components/data/table/FTableTitleCell';
import NumbersAndBudgetMixin from '../../../mixins/NumbersAndBudgetMixin';
import FRating from '../../../f-components/form-controls/ratings/FRating';
import FSwitch from '../../../f-components/form-controls/switches/FSwitch';
import FTableContactCell from '../../../f-components/data/table/FTableContactCell';
import DepartmentDisciplinePath from '../../misc/department-discipline-path/DepartmentDisciplinePath';
import FChipGroup from '../../../f-components/data/chip/FChipGroup';
import FChip from '../../../f-components/data/chip/FChip';
import FreelancerMixin from './FreelancerMixin';
import FContainer from '../../../f-components/layout/container/FContainer';
import FTableHeaderRow from '../../../f-components/data/table/FTableHeaderRow';
import FPagination from '../../../f-components/navigation/pagination/FPagination';
import FTablePlaceholder from '../../../f-components/loaders/placeholder/FTablePlaceholder';
import FBreadcrumbs from '../../../f-components/navigation/breadcrumbs/FBreadcrumbs';
import FBreadcrumb from '../../../f-components/navigation/breadcrumbs/FBreadcrumb';
import EntityActionButton from '../../misc/entities/EntityActionButton';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage.vue';

export default {
    name: 'freelancers-database-page',

    components: {
        FInlineMessage,
        EntityActionButton,
        FBreadcrumb,
        FBreadcrumbs,
        FTablePlaceholder,
        FPagination,
        FTableHeaderRow,
        FContainer,
        FChip,
        FChipGroup,
        DepartmentDisciplinePath,
        FTableContactCell,
        FSwitch,
        FRating,
        FTableTitleCell,
        EntityAvatar,
        FTableCell,
        FTableHeaderCell,
        FTableRow,
        FTable,
        FDropdown,
        FTextInput,
        FFilterItem,
        FFilterRow,
        FFilter,
        Page,
    },

    mixins: [
        EntityPaginatedFilteredOverviewMixin,
        OfficeOptionsMixin,
        NumbersAndBudgetMixin,
        FreelancerMixin,
    ],

    data() {
        return {
            searchQuery: null,
            office: null,
            currentlyWorking: false,
            archived: false,
        };
    },

    watch: {
        searchQuery() {
            this.onFilterChange();
        },
        office() {
            this.onFilterChange();
        },
        currentlyWorking() {
            this.onFilterChange();
        },
        archived() {
            this.onFilterChange();
        },
    },

    methods: {
        getEndpoint() {
            return APIEndpoints.FREELANCER_DATABASE;
        },

        getFilters() {
            return {
                'filter[query]': this.searchQuery,
                'filter[office]': this.office,
                'filter[currently_working]': this.currentlyWorking,
                'filter[archived]': this.archived,
            };
        },

        applyPreviousFilters(filters) {
            if (filters['filter[query]']) this.searchQuery = filters['filter[query]'];
            if (filters['filter[office]']) this.office = filters['filter[office]'];
            if (filters['filter[currently_working]']) this.currentlyWorking = filters['filter[currently_working]'];
            if (filters['filter[archived]']) this.archived = filters['filter[archived]'];
        },
    },
}
</script>

<style lang="scss">
.freelancers-database-page {
    .f-filter__container {
        max-width: 600px;
    }

    &__working-on {
        margin: 3px 0 0 -2px;
    }

    &__specialities {
        margin-top: 3px;
    }
}
</style>
