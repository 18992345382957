<template>
    <page
        class-name="authentication-page"
        title="Login"
        @authenticated="$router.push('/')"
        :skewed-header-foreground="null"
        skewed-footer-foreground="#f3f3f3"
        margin-top="0"
    >
        <f-center>
            <div>
                <logo/>

                <f-inline-message
                    v-if="error"
                    id="loginError"
                    variant="error"
                    :closable="false"
                >
                    {{ error }}
                </f-inline-message>

                <f-flex direction="column" gap="6px">
                    <div style="height: 12px;"/>
                    <f-button
                        :href="`${API_DOMAIN}/oauth/365?redirect_domain=${redirectDomain}`"
                        tag="a"
                        value="Login with"
                    >
                        <template #post>
                            <img alt="Microsoft 365" src="/img/microsoft365-logo.svg"/>
                            <div class="auth-button-post">hyspot.io</div>
                        </template>
                    </f-button>
                    <div style="height: 12px;"/>
                    <f-text type="subtitle">Alternatively</f-text>
                    <f-button
                        :href="`${API_DOMAIN}/oauth/gsuite?redirect_domain=${redirectDomain}`"
                        tag="a"
                        value="Login with"
                        size="small"
                    >
                        <template #post>
                            <img alt="Google Workspace" src="/img/google-workspace-logo.svg"/>
                            <div class="auth-button-post">codedazur.com</div>
                        </template>
                    </f-button>
                </f-flex>
            </div>
        </f-center>
    </page>
</template>

<script>
import APIDataStore from '../../../services/APIDataStore';
import Page from '../Page';
import FCenter from '../../../f-components/layout/center/FCenter';
import Logo from '../../misc/Logo';
import FInlineMessage from '../../../f-components/notice/inline-message/FInlineMessage';
import FButton from '../../../f-components/form-controls/buttons/FButton';
import {API_DOMAIN} from '../../../config/app';
import FFlex from '../../../f-components/layout/flex/FFlex.vue';
import FFormRowSpacer from '../../../f-components/form-controls/forms/FFormRowSpacer.vue';
import FText from '../../../f-components/layout/text/FText.vue';

export default {
    name: 'authentication-page',

    components: {
        FText,
        FFormRowSpacer,
        FFlex,
        FButton,
        FInlineMessage,
        Logo,
        FCenter,
        Page,
    },

    mounted() {
        APIDataStore.clearAllIntervals();
    },

    data() {
        return {
            error: this.$route.query.error,
            API_DOMAIN: API_DOMAIN,
            redirectDomain: encodeURIComponent(window.location.origin),
        }
    },
}
</script>

<style lang="scss">
.page-auth {
    // TODO: bit hacky needs refac
    .page-footer__pre {
        padding-top: 0;

        .f-text {
            display: none;
        }
    }
}

.authentication-page {
    .f-inline-message {
        max-width: 400px;
        margin-bottom: 25px;
    }

    .logo {
        margin: 25px auto;
        height: 85px;
    }

    .f-button {
        img {
            height: 14px;
            filter: brightness(0);
            padding: 0 10px;
            transition: filter .1s;
        }
    }

    .auth-button-post {
        padding: 0 10px;
        border-left: 1px solid #c4c4c4;
        color: #c4c4c4 !important;
        font-size: 90%;
    }
}

@media (hover: hover) {
    .authentication-page .f-button:hover {
        img {
            filter: brightness(0) invert(1);
        }
    }
}
</style>
